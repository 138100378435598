<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-account-list' }">Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <h1 class="text-h4 font-weight-light">Accounts</h1>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">My Accounts</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6" v-if="Array.isArray(accountList)">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Accounts ({{ accountList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="isFilterActive = !isFilterActive">
                                <font-awesome-icon :icon="['fas', 'filter']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                            <!-- <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" :color="primaryColor" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Overview</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>                             -->
                            <v-btn icon @click="navigateToCreateAccount">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text v-if="isFilterActive" class="filter-controls">
                            <p>Filter the list of accounts:</p>
                            <v-select v-model="filterAccountIsOpen" :items="filterAccountIsOpenChoices" outlined dense></v-select>
                        </v-card-text>
                        <v-divider v-if="isFilterActive"></v-divider>
                    <!-- <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon color="purple" @click="openCreateAccountDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-toolbar> -->
                        <!-- <v-divider></v-divider> -->
                        <v-list dense nav>
                            <v-list-item v-if="accountList.length === 0">No accounts yet.</v-list-item>
                            <v-list-item v-for="item in accountList" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                                <v-list-item-content class="text-start">
                                    {{ item.name }}
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-row justify="center" class="py-5" v-show="displayCreateAccount">
                            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                            <v-card elevation="4" class="px-10 pb-5 pt-8">
                                <v-form v-model="createAccountForm" ref="createAccountFormRef" @submit.prevent="onSubmitForm" @keyup.enter.native="onSubmitForm">
                                    <!-- <div v-if="add">
                                        <v-select :items="addAccountChoices" v-model="addAccountId" label="Select an account"></v-select>
                                    </div> -->
                                    <!-- <div v-if="create"> -->
                                        <!-- <v-text-field
                                            ref="nameInputRef"
                                            v-model=newAccountName
                                            label="Display Name"
                                            :rules="newAccountNameRules"
                                            validate-on-blur
                                            :color="primaryColor"
                                            required
                                            hint="The name for the account"
                                            type="text"
                                            outlined
                                        >
                                        </v-text-field> -->

                                    <!-- </div> -->
                                    <v-row justify="center">
                                        <v-card-actions>
                                            <!-- <v-btn elevation="4" class="purple white--text" @click="addAccount" :disabled="!createAccountForm" v-if="add">
                                                <font-awesome-icon icon="check" fixed-width/>
                                                <span class="ml-2">Add</span>
                                            </v-btn> -->
                                            <v-btn elevation="4" :style="primaryButtonStyle" @click="onSubmitForm" :disabled="!createAccountForm">
                                                <font-awesome-icon icon="check" fixed-width/>
                                                <span class="ml-2">Create</span>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-row>
                                </v-form>
                            </v-card>
                            </v-col>
                        </v-row>
                        <!-- <CreateAccountDialog v-model="createAccountDialogVisible" @cancel="createAccountDialogVisible = false" @created="onAccountCreated"/> -->
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style lang="css">
/* hide input message area in filter controls because user cannot select/enter an incorrect value */
.filter-controls div.v-input div.v-text-field__details {
    display: none !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
    },
    data: () => ({
        // account: null,
        error: null,
        // submitFormTimestamp: null,
        // createAccountDialogVisible: false,
        // create account
        // displayCreateAccount: false,
        // createAccountForm: null,
        // newAccountName: null,
        // newAccountNameRules: [
        //     (v) => !!v || 'Display name is required',
        //     (v) => !v || isValidName(compact(v)) || 'Account name is required',
        // ],
        // add account
        // addAccountChoices: [],
        // addAccountId: null,
        isFilterActive: false,
        filterAccountIsOpen: true,
        filterAccountIsOpenChoices: [
            { value: true, text: 'Open' },
            { value: false, text: 'Closed' },
        ],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            accountList: (state) => state.accountList,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session?.isAuthenticated;
        },
        filters() {
            if (!this.isFilterActive) {
                return {};
            }
            const query = {};
            query.is_open = this.filterAccountIsOpen ?? true;
            return query;
        },
    },
    watch: {
        isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                console.log('accountlist.vue: isAuthenticated is now set');
                this.init();
            }
        },
        focus() {
            this.$store.dispatch('loadAccountList', { filters: this.filters });
        },
        filters() {
            this.$store.dispatch('loadAccountList', { filters: this.filters });
        },
    },
    methods: {
        async init() {
            console.log('accountlist.vue: init');
            this.$store.dispatch('loadAccountList', { filters: this.filters });
        },
        async onClickItem(accountId) {
            // NOTE: the accountList is really linkAccountUserList, so each item in it is not exactly the same as an account item; for this reason we load the account here and not just assign the selected item
            await this.loadAccount(accountId);
            this.$nav.push({ name: 'account-dashboard', params: { accountId } });
        },
        /*
        async loadAccountList() {
            console.log('accountlist.vue: loadAccountList');
            try {
                this.$store.commit('loading', { loadAccountList: true });
                console.log(`AccountList.vue: loadAccountList.search ${this.user.id}`);
                const result = await this.$client.site(this.brandprofile).user(this.user.id).account.search();
                this.accountList = result.list;
            } catch (err) {
                console.error('accountlist.vue: failed to load account list', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        */
        onSubmitForm() {
            console.error('not implemented');
        },
        navigateToCreateAccount() {
            this.$router.push({ name: 'user-create-account' });
        },
        async loadAccount(accountId) {
            await this.$store.dispatch('loadAccount', { accountId });
            if (this.account) {
                this.$nav.set('account_id', this.account.id);
            }
        },
    },
    mounted() {
        console.log('accountlist.vue: mounted');
        // when user is looking at the account list, we de-select whatever account may have been selected because now they're in between accounts
        this.$store.commit('account', null);
        this.$nav.set('account_id', null);
        if (this.isAuthenticated) {
            console.log('accountlist.vue: isAuthenticated');
            this.init();
        }
    },
};
</script>
